.footer-footer {
  width: 100%;
  height: 324px;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-left: 26px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: 22px;
  justify-content: space-between;
}
.footer-container {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.footer-container1 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.footer-container2 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.footer-information {
  gap: var(--dl-space-space-fiveunits);
  height: 254px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.footer-content {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.footer-header {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.footer-text {
  color: rgb(18, 40, 33);
  width: 100%;
  font-size: 18px;
  max-width: 600px;
  font-family: Lato;
  line-height: 27px;
}
.footer-contact-list {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.footer-contact {
  gap: 11px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.footer-icon {
  width: 24px;
  height: 24px;
}
.footer-text1 {
  color: rgb(18, 40, 33);
  width: 100%;
  font-size: 18px;
  font-family: Lato;
  line-height: 18px;
}
.footer-copyright {
  color: rgb(18, 40, 33);
  width: 100%;
  font-size: 14px;
  margin-top: 10px;
  font-family: Lato;
  line-height: 21px;
}
.footer-container3 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.footer-text2 {
  color: #0967b3;
  align-self: flex-end;
  margin-top: 0px;
  text-align: center;
  margin-left: 0px;
  padding-top: 0px;
  border-color: #61B4DD;
  border-width: 3px;
  margin-right: 0px;
  padding-left: 6px;
  padding-right: 6px;
  padding-bottom: 0px;
  text-decoration: none;
}
@media(max-width: 991px) {
  .footer-footer {
    gap: var(--dl-space-space-threeunits);
    align-items: flex-start;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: flex-start;
  }
  .footer-content {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .footer-header {
    gap: var(--dl-space-space-unit);
  }
  .footer-copyright {
    display: none;
  }
}
