.card-card {
  position: relative;
}
.card-header {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.card-icon {
  height: 50px;
  object-fit: cover;
}
.card-heading {
  color: #352b01;
  font-size: 24px;
  max-width: 600px;
  font-style: normal;
  font-weight: 500;
}
.card-text {
  color: #352b01;
  max-width: 600px;
  font-family: Lato;
  line-height: 24px;
}
.card-root-class-name {
  background-color: #efd154;
}
.card-root-class-name1 {
  background-color: #efd154;
}
.card-root-class-name2 {
  background-color: #efd154;
}
.card-root-class-name3 {
  background-color: #efd154;
}
